footer {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f7f6f6;
}

footer .upper-footer {
    width: 100%;
    border-bottom: 1px solid rgba(213,232,243,0.33);
}

footer .bottom-footer {
    width: 100%;
    padding: 84px 136px 103px 136px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

footer .bottom-footer a#footer-home img {
    height: auto;
}

footer .bottom-footer .footer-menu {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-grow: 1;
    max-width: 843px;
    justify-content: flex-end;
    align-items: flex-start;
}

footer .bottom-footer .footer-menu .menu-block {
    margin-left: 79px;
}

footer .bottom-footer .footer-menu .menu-block h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 104.5%;
    color: #931d11;
    margin: 0 0 8px 0;
    position: relative;
    text-align: left;
}

footer p {
    color: #931d11;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.6px;
    line-height: 24px;
    text-align: justify;
}

ul {
    list-style: none;
    padding-left:0px;
    text-align:left;
}

footer .bottom-footer .footer-menu .menu-block ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 104.5%;
    color: #931d11;
    padding: 4px 0;
}

a {
    color: #931d11;
    text-decoration: none;
    background-color: transparent;
}


@media (max-width: 488px) {
  footer .bottom-footer {
    padding: 20px;
  }

  footer .bottom-footer .footer-menu {
    display: block;
  }

  footer .bottom-footer .footer-menu .menu-block {
    margin-left: 0px;
    float: left;
    width: 50%;
  }

  footer .bottom-footer .footer-menu .menu-block h5 {
    text-align: center;
  }

  footer .bottom-footer .footer-menu .menu-block ul li {
    text-align: center;
  }

  .contact-information {
    width: 100%!important;
  }
}
