.home-banner {
  width: 100%;
  position: relative;
  /* padding-top: 95px; */
  background-color: #EFEFEF;
}

.home-banner .first-fold-wrapper {
    width: 100%;
    padding: 0 119px;
    box-sizing: border-box;
}

.home-banner .first-fold-wrapper .home-first-fold {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    justify-content: space-between;
    /* align-items: flex-start; */
    min-height: 300px;
}

.home-banner .first-fold-wrapper .home-first-fold .left-box {
    max-width: 40%;
    width: 100%;
    margin-top: 122px;
    flex: 1;
}

.home-banner .first-fold-wrapper .home-first-fold .left-box h1 {
    margin-top: 0;
    margin-bottom: 19px;
    font-style: normal;
    font-weight: 700;
    font-size: 52px;
    line-height: 105%;
    letter-spacing: -0.035em;
    color: #931d11;
    text-align: left;
}

.home-banner .first-fold-wrapper .home-first-fold .left-box p {
    max-width: 671px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #390606;
    opacity: 0.6;
    margin-bottom: 75px;
    text-align:left;
}

.home-banner .first-fold-wrapper .home-first-fold .right-box {
    max-width: 30%;
    width: 100%;
    /* height: 485px; */
    background-color: #931d11;
    flex: 1;
}

.home-banner .first-fold-wrapper .home-first-fold .right-box img {
  width: 364px;
  height: 364px;
  position: absolute;
  border-radius: 50%;
  top: 18%;
  right: 20%;
}

.feature-block-wrapper {
    width: 100%;
    padding: 110px 119px 0px 119px;
    box-sizing: border-box;
}

.feature-block-wrapper h2 {
    max-width: 796px;
    width: 100%;
    margin: 0 auto;
    font-weight: 700;
    font-size: 56px;
    line-height: 115%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #931d11;
}

.feature-block-wrapper .feature-inner-wrap {
    width: 100%;
    box-sizing: border-box;
    margin-top: 21px;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.feature-block-wrapper .feature-inner-wrap .feature-block {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc((100% - 117px)/2);
    margin-top: 101px;
    flex-wrap: wrap;
}

.feature-block-wrapper .feature-inner-wrap .feature-block .icon-block.warehousing {
    /* background: url("../images/home-page-sprite.png") no-repeat 81.746% 27.925%; */
    background-size: auto;
    background-size: 444px 444px;
    width: 66px;
    height: 47px;
}

.feature-block-wrapper .feature-inner-wrap .feature-block .info-block {
    width: calc(100% - 66px);
    padding-left: 52px;
    box-sizing: border-box;
    text-align: left;
}

.feature-block-wrapper .feature-inner-wrap .feature-block .info-block h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: #931d11;
}

.feature-block-wrapper .feature-inner-wrap .feature-block .info-block p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 8px;
    line-height: 150%;
    color: #390606;
    opacity: 0.8;
}

.stats-block {
    width: 100%;
    /* background-image: url("../images/stats-background.png"); */
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
}

.stats-block .stats-wrapper {
    width: 100%;
    padding: 79px 131px 99px 131px;
    box-sizing: border-box;
    border-top: 1px solid #931d11;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    justify-content: space-between;
    align-items: flex-end;
}

.stats-block .stats-wrapper .indiv-stat {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    justify-content: flex-start;
    align-items: center;
}

.stats-block .stats-wrapper .indiv-stat h3 {
    min-width: 228px;
    font-style: normal;
    font-weight: 700;
    font-size: 92px;
    line-height: 84.5%;
    letter-spacing: -0.05em;
    color: #931d11;
    margin-right: 27px;
}

.stats-block .stats-wrapper .indiv-stat span {
    display: block;
    width: 123px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 118.5%;
    letter-spacing: -0.01em;
    color: #931d11;
}

.partner-block {
    padding: 106px 0px 108px 113px;
    background-color: #EECDD0;
}

.partner-block .partner-wrapper {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    align-items: center;
    justify-content: space-between;
}

.partner-block .partner-wrapper .info-txt-wrap {
    max-width: 752px;
    text-align: left;
}

.partner-block .partner-wrapper .info-txt-wrap h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #931d11;;
    margin: 0 0 29px 0;
}

.partner-block .partner-wrapper .info-txt-wrap p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #390606;
    opacity: 0.6;
    margin: 0 0 46px 0;
}

@media (max-width: 488px) {
  .small-screen-hide {
    display: none;
  }

  .small-screen-block-display {
    display: block;
  }

  .home-banner .first-fold-wrapper {
    padding: 0 0 0 10px;
  }

  .home-banner .first-fold-wrapper .home-first-fold .left-box {
    margin-top: 0px;
    max-width: 60%;
  }

  .feature-block-wrapper {
    width: 100%;
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
  }

  .feature-block-wrapper .feature-inner-wrap .feature-block {
    width: 100%;
    display: block;
    margin-top: 10px;
  }

  .feature-block-wrapper .feature-inner-wrap .feature-block .info-block {
    text-align: center;
    width: 100%;
    padding-left: 0px;
  }

  .stats-block .stats-wrapper {
    padding: 20px;
    box-sizing: border-box;
    border-top: 1px solid #931d11;
    display: block;
  }

  .stats-block .stats-wrapper .indiv-stat {
    display: block;
    padding-bottom: 20px;
  }

  .stats-block .stats-wrapper .indiv-stat span {
    display: contents;
  }

  .partner-block {
    padding: 20px;
    background-color: #EECDD0;
  }
  .partner-block .partner-wrapper .info-txt-wrap h3 {
    font-size: 42px;
    letter-spacing: 0;
    margin: 0 0 29px 0;
  }
}
