.hide-class {
  display: none;
}

@media (max-width: 1100px) and (min-width: 489px) {
}

@media (max-width: 488px) {
  .small-screen-hide {
    display: none;
  }

  .small-screen-block-display {
    display: block;
  }
}
