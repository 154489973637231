.bg-transparent {
    background-color: #EFEFEF!important;
}

.navbar-light .navbar-nav .nav-link {
    color: black;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: #931d11;
}

.header-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.horizontal-align {
  display: flex;
  justify-content: center;
  color: white;
}

.center {
  display: flex;
  justify-content: center
}

.hide-class {
  display: none;
}

@media (max-width: 1100px) and (min-width: 489px) {
}

@media (max-width: 488px) {
  .small-screen-hide {
    display: none;
  }
}
