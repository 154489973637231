.App {
  text-align: center;
  font-size: 14px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.table td, .table th {
  padding: 0.25rem;
  vertical-align: middle;
}

.table {
  font-size: 12px;
}


.container-fluid {
  margin: 0px;
  padding: 0px;
}

.sidebar {
  position: fixed;     /* Fixed Sidebar (stay in place on scroll and position relative to viewport) */
  height: 100%;
  top: 75.25px;
  left: 0px;
  width: 100px;     /* Set the width of the sidebar */
  z-index: 500;      /* Stay on top of everything */
  background-color: #FFFFFF;
  overflow-x: hidden;     /* Disable horizontal scroll */
  padding-top: 10px;
  overflow: visible;
  border-right: 1px solid rgba(0,0,0,.15);
}

.dropdown, .dropleft, .dropright, .dropup {
  padding: 0px!important;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-link {
    font-size: 18px!important;
    font-weight: 400;
}

.sidebar-element {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0,0,0,.15);
  padding-bottom: 5px;
  }

.sidebar-menu-option {
  color: black;
}

.icon {
  height: 30px;
  width: auto;
}

.logo {
  height: 60px;
  width: auto;
}

.content-wrapper {
  width: calc(100% - 100px);
  margin-left: 100px;
  padding: 0px 5px 0px 5px;
}
/* .sidebar-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: .25rem;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.align-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.nav-link {
  font-size: 16px;
}

.dropdown, .dropleft, .dropright, .dropup {
  padding: 5px;
}

.map-view {
  height: 700px;
  width: 100%;
  padding-top: 50px;
  align-items: center;
}

.route-map-view {
  height: 700px;
  width: 100%;
  padding-top: 50px;
  align-items: center;
}

.map-size {
  height: 100%;
  width: 100%
}

.input-group {
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align:center;
}

.btn.disabled, .btn:disabled {
    opacity: .45;
    border: gray;
    cursor: default;
}

.orderOnePageUpdateFormDiv {
  display: block;
}

.orderOneSelect {
  display: initial;
  width: 20%;
}

.btn-primary{
  background-color: #9A1E10;
  border-color: #9A1E10;
}

.space-top {
  padding-top: 20px;
}

.space-top-extra {
  padding-top: 40px;
}

.hidden {
  display:none;
}

.btn {
  border-radius: 0px;
  font-weight: 400;
  font-size: 14px;
  margin: 2px;
}

input.btn-primary {
  margin-top: 0px!important;
}
/* Filter Div */
.filter-button {
  margin-top:-20px;
}

.form-control {
  font-size: 14px;
}

.form-edit-order-create {
  width:10%;
  margin-right: 30px;
}

.update-order {
  background-color: #0000002e;
  border: 1px solid black;
}

.bulk-update-order-button {
  margin: 10px;
}

.bulk-update-order-button-2 {
  margin-top: -20px;
}

input.btn-primary {
  margin-top: 15px;
  text-align: center;
}

/* Bulk Update */
.bulk-update {
  display: flex;
  color: black;
  border-radius: 2px;
  justify-content: center;
}

.bulk-update-select {
  padding:10px;
}

.bod-text {
  margin: 13px 10px 10px 10px;
}

.bulk-update-button {
  color: black;
  background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
  border-color: black;
}

.iuo {
  display: flex;
  justify-content: center;
}

.input-inputButton {
  display: flex;
  justify-content: center;
}

.variation-qty-input {
  width: 50px;
  padding: .375rem .75rem;
  text-align: center;
  display: block;
  padding-right: 10px;
  margin-right: 10px;
}

.margin-right {
  margin-right: 10px
}

/* Header Component */
.align-header {
  display: flex;
}

.flex-display {
  display: flex;
}

.header-align {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  font-size: 24px;
  border-bottom: 1px solid #ccc;
}

.header {
  padding-top: 10px;
}

.align-upload {
  display: flex;
  justify-content: center;
}

.login {
  height: 25px;
  width: auto;
}

.login-text {
  font-size: 10px;
  margin-bottom: 0px;
  color: black;
}

.logout-text {
  font-size: 10px;
  margin-bottom: 0px;
  color: black;
}

.login-div {
  padding-left: 10px;
}

/* Page Info */
.flex {
  display: flex;
}
/* Table Modification*/
.col-width-address {
  width: 300px;
}

.col-width-200px {
  width: 200px;
}

.col-width-update {
  width: 100px;
}

.no-background-button {
  background: white;
  border: none;
  font-size: 16px;
}

body {
  counter-reset: Serial;    /* Set the Serial counter to 0 */
}

.row-number td:first-child:before
{
  counter-increment: Serial;      /* Increment the Serial counter */
  content: counter(Serial); /* Display the counter */
}

.date-crossed {
  color: red;
  font-weight: bold;
}

.low-stock-warning {
  color: goldenrod;
  font-weight: bold;
}

.stock-out {
  color: red;
  font-weight: bold;
}

.payment-made {
  color: green;
  font-weight: bold;
}

.row-text-green {
  color: green;
  font-weight: bold;
}

.row-text-red {
  color: red;
  font-weight: bold;
}

.variation {
  background-color: azure;
}

/*Truncate Product Information*/
.truncate {
  width: 50ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-item-disabled {
  color: #7d8d9e;
}

.order-adjustment {
  padding: 10px !important;
}

/*dashboard css*/
.dashboard {
  float: left;
  padding: 1px;
}

.input-group-modifier {
  flex-wrap: inherit;
}

.input-add {
  padding-top: 10px
}

/*Product Image Size*/

.product-image {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.product-id-text {
  font-size: 12px;
}

/*Input for Order Add*/
.ecwid-order-input {
  width: 150px;
  padding: .375rem .75rem;
  text-align: center;
  display: block;
}

/*Input for Dimensions*/
.dim-input {
  width: 50px;
  padding: .375rem .75rem;
  text-align: center;
  display: block;
}

.qty-input {
  width: 50px;
  padding: .375rem .75rem;
  text-align: center;
}

.tracking-input {
  width: 150px;
  padding: .375rem .75rem;
  text-align: center;
  display: block;
  padding-right: 10px
}

.comments {
  width: 250px;
  height: 100px;
  padding: .375rem .75rem;
  text-align: center;
  display: block;
  padding-right: 10px
}

.dim-input-span {
  padding-right: 10px
}

/* reports page */
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  background: #F4CECE;
  border: 0;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chart-card-body {
  border: 0;
  background: #ffffff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 7%);
  border-radius: 15px;
  overflow: hidden;
}

.no-border {
  border-style: none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width: 1100px) and (min-width: 489px) {
  .section-hide {
    display: none;
  }

  .sidebar {
    top: 123px;
  }

}

@media (max-width: 488px) {
  /* Header Component */
  .align-header {
    justify-content: center;
  }

  .section-hide {
    display: none;
  }

  .space-top-extra {
    padding-top: 60px;
  }

  .dashboard {
    float:none;
  }

  .row-space {
    margin-bottom: 10px;
  }

  .sidebar {
    top: 123px;
  }


  /* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	tr { border: 1px solid #ccc; }

  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
    padding-top: 20px;
  }

	td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50%;
	}

	td:before {
		/* Now like a table header */
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

  .flex {
    display: block;
  }

  .orderOneSelect {
    display: initial;
    width: 80%;
    margin-bottom: 10px;
  }

  .content-wrapper {
    width: 100%;
    margin-left: 0px;
    padding: 0px 5px 0px 5px;
  }

  .header-align {
    display: block;
    justify-content: space-between;
    padding-left: 0px;
    font-size: 24px;
    border-bottom: 1px solid #ccc;
  }

}
